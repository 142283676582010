<template>
  <div>
    <CRow>
      <CCol md="4">
          <CCard>
            <CCardHeader>
              <CRow>
                <CCol col="9">
                  <h4>User Tier List</h4>
                </CCol>
              </CRow>
            </CCardHeader>
            <CCardBody>
              <CRow>
                <CCol col="12" class="text-left">
                  <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <div class="table-responsive">
                    <b-table striped hover  :items="itemsTier" :fields="fieldsTier" >
                      <template v-slot:cell(action)="{ item }">
                        <b-button variant="primary" @click="openTopUpModal(item.tier)">Top-up Configuration</b-button>
                        <b-modal :id="'modal-'+item.tier" title="Earmark Top-up Configuration">

                          <CForm>
                          <b-row>
                            <b-col sm="3">
                              <label for="input-small" >User Tier</label>
                            </b-col>
                            <b-col sm="9" class="font-weight-bold">
                              {{ item.tier }}
                            </b-col>
                          </b-row>
                          <br />
                          <b-row>
                            <b-col sm="3">
                              <label for="input-small" >Active</label>
                            </b-col>
                            <b-col sm="9">
                              <b-form-checkbox v-model="active" name="check-button" switch size="lg" >
                              </b-form-checkbox>
                            </b-col>
                          </b-row>
                          <CInput
                              type="number"
                              label="Expiry Days"
                              v-model="expiryDays"
                              min="1"
                              placeholder="Enter Expiry Days"
                              horizontal
                          />
                          <b-table striped hover responsive :items="itemsModal" :fields="fieldsModal">
                            <template v-slot:cell(percentage)="{item}">
                              <input type="number" min="1" :class="'percentage-'+item.trans_types+'-'+userTier+' form-control percentageModal'" name="percentage[]" v-model="percentageByType[item.trans_types]" max="100" @keypress="keyPressPercentage" />
                            </template>
                          </b-table>

                          </CForm>
                          <CRow>
                            <CCol col="12" class="text-left">
                              <CAlert v-bind:color="color" v-if="seen">{{ msg }}</CAlert>
                            </CCol>
                          </CRow>
                          <template #modal-footer>
                            <button class="btn btn-success m-1" @click="actionSubmit">Submit</button>
                            <button class="btn btn-danger m-1"  @click="$bvModal.hide('modal-'+userTier)">Close</button>
                          </template>
                        </b-modal>
                      </template>
                    </b-table>
                  </div>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
      </CCol>
      <CCol md="8">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol col="9">
                <h4>Earmark Top-up Config List</h4>
              </CCol>
            </CRow>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <div class="table-responsive">
                  <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" sort-by="tier" :sort-asc="true" >
                    <template v-slot:cell(trans_types)="{ item }">
                      <span v-html="$options.filters.matchTransTypePercentage(item.trans_types+'|'+item.percentages)"></span>
                    </template>
                  </b-table>
                </div>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>

<style scoped>
.list-group shadow vbt-autcomplete-list {
  background: black;
}
</style>


<script>
import AuthService from '@/api/AuthService.js';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
//import 'bootstrap/scss/bootstrap.scss';

export default {
  name: 'earmarkTopUpConfig',
  components: {
    VueTypeaheadBootstrap,
  },
  data() {
    return {
      optCurrency: [],
      optNumber:[],
      optTransType:[],
      optUsrTier:[],
      optWallet:[],
      phoneNum:'',
      baseItems:[],
      baseUserId:[],
      itemsModal:[],
      percentageByType:[],
      fieldsModal: [
        {
          name: 'trans_types',
          key: 'trans_types',
          label: 'Transaction Type'
        },{
          name: 'percentage',
          key: 'percentage',
          label: 'Percentage'
        }
      ],
      items:[],
      fields: [
        {
          name:'tier',
          key: 'tier',
          label : 'Tier',
          sortable: true
        },
        {
          key: 'trans_types',
          label : 'Transaction Type : Percentage',
          sortable: true
        },
        {
          key: 'expiry_days',
          label : 'Expiry Days',
          sortable: true
        },
        {
          key: 'active',
          label : 'Active',
          sortable: true
        }
      ],
      itemsTier: [],
      fieldsTier: [
        {
          name:'tier',
          key: 'tier',
          thStyle: 'width:60%',
          label : 'User Tier',
          sortable: true
        },
        {
          key: 'action',
          label : 'Action',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false
        }
      ],
      currentPage: 1,
      perPage: 10,
      filter: null,
      userId:'',
      displayName:'',
      transType:'',
      userTier:'',
      percentage:'',
      expiryDays:'',
      walletId:'',
      currency:'',
      amount:'',
      reason:'',
      seen:false,
      active:false,
      msg :'',
      color:''
    };
  },
  filters: {
      matchTransTypePercentage(value){
        if(!value) return ''
        value = value.toString()
        let arrVal = value.split("|")
        let transTypeString = arrVal[0];
        let percentageString = arrVal[1];
        let transType = transTypeString.split(",");
        let percentage = percentageString.split(",");
        let newValue = []
        transType.forEach((valueTransType, index) => {
            newValue.push(`<strong>` + valueTransType + `</strong> : ` + percentage[index] + `%`)
        })
        return newValue.join('<br />')
      },
  },
  created () {
    this.getConfigList();
    this.listEarmarkTopupConfig();
    this.fetchUsers();
  },
  methods: {

    actionSubmit(){

      let params = {}; var validate = [];
      params["active"] = this.active;
      params["tier"] =  this.userTier;
      params["expiry_days"] = this.expiryDays;

      for (var key in params) {
        if(params[key] === "" || typeof params[key] !== 'undefined') { validate.push(false); } else { validate.push(true); }
      }

      if(!validate.includes("false")){
        //console.log('submit');
        console.log(this.percentageByType);
        let totalPercentage = 0
        let dataPercentages = [];
        let dataTransTypes  = [];
        let percentType = this.percentageByType;
        for(let keyType in percentType) {
            totalPercentage += Number(percentType[keyType]);
            if(percentType[keyType] !== '') {
              dataTransTypes.push(keyType);
              dataPercentages.push(percentType[keyType]);
            }
        }
        if(totalPercentage === 100) {
            let percentages = '';
            let trans_types = '';
            if(dataPercentages.length > 0) {
                percentages = dataPercentages.join(",");
            }
            if(dataTransTypes.length > 0) {
                trans_types = dataTransTypes.join(",");
            }
            params['percentages'] = percentages;
            params['trans_types'] = trans_types;
            AuthService.saveEarMarkTopUpConfig(params).then(resp => {
              console.log(resp);
              this.getConfigList();
              this.listEarmarkTopupConfig();
              this.msg   = 'Save Earmark Top-up Config for '+this.userTier+' is successful';
              this.color = 'success'; this.seen  = true;
              setTimeout( () =>  this.seen=false , 5000);
              this.$root.$emit("bv::hide::modal", "modal-"+this.userTier);
            }, error => {
              console.log(error);
            });
        } else {
            console.log(totalPercentage);
            console.log(params);
            this.msg   = 'Total Percentage must be 100';
            this.color = 'warning'; this.seen  = true;
            setTimeout( () =>  this.seen=false , 5000);
        }
      } else {
        //console.log('send message');
        this.msg   = 'Data Not Complete, All Data Required!';
        this.color = 'warning'; this.seen  = true;
        setTimeout( () =>  this.seen=false , 5000);
      }

    },

    actionChange(){
      if(this.phoneNum){
        this.currency='';
        this.amount='';
        this.reason='';
        var temp = this.baseItems;
        var phoneNum = this.phoneNum;
        var user = this.baseUserId;
        let index = temp.findIndex(temp => temp === phoneNum );
        this.userId = user[index];
        var detail = this.getUserDetail(user[index]);
      } else {
        this.msg   = 'Please select phone number first !';
        this.color = 'warning'; this.seen  = true;
        setTimeout( () =>  this.seen=false , 5000);
      }
    },

    getRowByTier: function(arrayToFind, findVal) {
      return arrayToFind.filter(p => p.tier == findVal);
    },

    openTopUpModal: function(tier){
      // <handle with your data>
      let dataFind = this.getRowByTier(this.items, tier);
      let transType = (dataFind[0].trans_types !== undefined) ? dataFind[0].trans_types : '';
      let isActive = false;
      let percentages = '';
      let expiryDays = '';
      if (transType !== '') {
          isActive = (dataFind[0].trans_types !== undefined) ? dataFind[0].active : false;
          expiryDays = (dataFind[0].expiry_days !== undefined) ? dataFind[0].expiry_days : false;
          percentages = (dataFind[0].percentages !== undefined) ? dataFind[0].percentages : false;
          let arrTt = transType.split(",");
          let arrPercentage = percentages.split(",");
          let arrData = [];
          this.percentageByType.forEach((valueOld, indexOld) => {
              if(valueOld !== ''){
                  arrData[indexOld] = '';
              }
          });
          arrTt.forEach((valueType, indexType) => {
              console.log('percentage-'+valueType+'-'+tier+', value :'+arrPercentage[indexType]);
              arrData[valueType] = arrPercentage[indexType];
          });
          this.percentageByType = arrData;
          console.log(this.percentageByType);
          this.$root.$emit("bv::show::modal", "modal-"+tier);
      }
      console.log(isActive);
      this.active = isActive;
      this.userTier = tier;
      this.expiryDays = expiryDays;

    },

    creditUser: function(params) {
      AuthService.creditUser(params).then(resp => {
        //console.log(resp);
        if(resp.message=='Success'){
          this.msg   = 'Credit user successfully done';
          this.color = 'success';  this.seen  = true;

          this.phoneNum='';
          this.userId='';
          this.displayName='';
          this.walletId='';
          this.currency='';
          this.amount='';
          this.reason='';
          //this.seen=false;

        } else {
          this.msg   = resp.data.message;
          this.color = 'warning'; this.seen  = true;
          setTimeout( () =>  this.seen=false , 5000);
        }

      }, error => {
        console.log('error');
      });
    },

    listEarmarkTopupConfig : function (){
        AuthService.getEarMarkTopUpConfig().then(resp => {
            if(resp.hasOwnProperty('payload')) {
              this.items = resp.payload;
            }
        }, error => {
           console.log(error);
        });
    },

    getUserDetail: function(id) {
      AuthService.getUserDetail(id).then(resp => {

        var items = resp.wallets;
        var wallets = [];
        for (var key in items) {
          var listName = items[key].walletId;
          wallets.push({ value: listName  , text: listName });
        }

        this.displayName = resp.displayName;
        this.optWallet = wallets;

      }, error => {
        this.loading = false;
      });
    },

    fetchUsers: function() {
      AuthService.getUserList({page:0, size:10000}).then(resp => {

        var users = resp.payload;
        var items = []; var userId=[];

        for (var key in users) {
          items.push(users[key].mobileNumber.number);
          userId.push(users[key].userIdentify.userId);
        }

        this.baseItems = items;
        this.baseUserId = userId;
        this.optNumber = items;

      }, error => {
        this.loading = false;
      });
    },

    sortByProperty(property){
      return function(a,b){
        if(a[property] > b[property])
          return 1;
        else if(a[property] < b[property])
          return -1;
        return 0;
      }
    },

    keyPressPercentage(evt){
      var theEvent = evt || window.event;
      var key = theEvent.keyCode || theEvent.which;
      var keyCode = key;
      key = String.fromCharCode(key);
      if (key.length == 0) return;
      var regex = /^\d$/;
      if (keyCode == 188 || keyCode == 190) {
        return;
      } else {
        if (!regex.test(key)) {
          theEvent.returnValue = false;
          if (theEvent.preventDefault) theEvent.preventDefault();
        }
      }
    },

    getConfigList: function() {
      AuthService.getConfigList().then(resp => {

        var currSrc=[]; var temp = [];
        var arrcurrency = resp.currency;
        for (const item of arrcurrency){ temp.push(item); }
        temp.sort();
        for (var key in temp) {
          var curr = temp[key];
          currSrc.push({ value: curr , text: curr });
        }
        this.optCurrency = currSrc;

        var typeSrc=[];
        var modalItems = [];
        let percentType = [];
        var arr_notIncludes = ['P2P_OUTSIDE_GROUP', 'P2P_OUTSIDE_COUNTRY', 'P2P_SEND_UNREG_USER', 'REQUEST_MONEY', 'P2M_PREFERRED_PARTNERS_ONLY', 'BY_INVITE', 'BY_REQUEST', 'TOPUPCARD', 'TOPUP_CHECKOUT', 'TOPUP_BANK', 'TOPUP_AUTO'];
        var arrTypes = resp.groupFeatures;
        let tType = '';
        for (let key2 in arrTypes) {
          let item2 = arrTypes[key2];
          if(!arr_notIncludes.includes(item2)) {
            if (key2 == 0) tType = arrTypes[key2];
            typeSrc.push({value: item2, text: item2});
            modalItems.push({trans_types: item2});
            percentType[item2] = '';
          }
        }

        this.transType = tType;
        this.optTransType = typeSrc;
        this.percentageByType = percentType;
        console.log(percentType);
        modalItems.sort(this.sortByProperty("trans_types"));
        this.itemsModal = modalItems;

        let tierSrc=[];
        let tierItems=[];
        let arrTier = resp.userTierList;
        let uTier = '';
        for (let key3 in arrTier) {
          let item3 = arrTier[key3];
          if(key3 == 0) uTier = arrTier[key3];
          tierSrc.push({ value: item3 , text: item3 });
          tierItems.push({tier: item3});
        }
        this.userTier = uTier;
        this.itemsTier = tierItems;
        this.optUsrTier = tierSrc;
      }, error => {
        console.log('error');
      });
    },

  }
}
</script>
